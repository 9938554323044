import React from 'react';
import styled from "styled-components";
import colors from '../../styles/colors';
import search from '../../images/search.svg';
import remove from '../../images/remove.svg';

const SearchBox = (props) => {
  return (
    <SearchContainer>
      <SearchInput 
        innerRef={props.setSearchBoxRef}
        type={"search"}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onArrowKeys}
        tabIndex={0}
        placeholder={props.placeholder}
        autoFocus
      />
    </SearchContainer>
  );
};

const SearchHits = (props) => {
  const historyMapLength = props.historyMap ? Object.values(props.historyMap).length > 0 ? Object.values(props.historyMap)[0].length : 0 : 0;
  return (
    <Hits>
      {props.hits.map(hit => {
        const historyMap = props.historyMap ? props.historyMap[hit.id] ? props.historyMap[hit.id] : Array.apply(null, Array(historyMapLength)).map(Number.prototype.valueOf, 0) : null;
        return (
          React.cloneElement(props.children, { key: hit.id, id: hit.id, name: hit.name, description: hit.description, status: hit.status, canDelete: hit.canDelete, historyMap, historyMapIndex: props.historyMapIndex })
        );
      })}

      { props.onNewClick != null ? <SearchHit key={'new'} id={'new'} onClick={props.onNewClick} name={props.newText} /> : null }
    </Hits>
  );
};

const SearchHit = (props) => {
  return (
      <Hit id={props.id} onClick={props.onClick}>
        <Container>
          <ItemText>{props.name}</ItemText>
          <ItemDescription>{props.historyMap ? <HistoryMap hitId={props.id} items={props.historyMap} index={props.historyMapIndex} /> : null}{props.description} { props.status ? <ItemStatus>{props.status}</ItemStatus> : null }</ItemDescription>
        </Container>
        { props.canDelete && props.onDelete != null ? <SecondaryAction onClick={(event) => props.onDelete(event, props.id)}><ItemIcon src={remove} /></SecondaryAction> : null }
        { props.onDetailsClick != null ? <SecondaryAction onClick={(event) => props.onDetailsClick(event, props.id)}>&gt;</SecondaryAction> : null }
      </Hit>
  );
};

const HistoryMap = (props) => {
  return (
    <HistoryMapContainer>
      { props.items.map((item, index) => {
        return (<HistoryMapItem key={`${props.hitId}-${index}`} item={item} active={index===props.index} />);
      })}
    </HistoryMapContainer>
  );
}

const HistoryMapItem = (props) => {
  return props.item === 1 ?
    <HistoryMapItemPresent active={props.active} />
  :
    <HistoryMapItemMissing />
}

const HistoryMapContainer = styled.div`
  display: flex;
  margin-right: 0.75rem;
`;

const HistoryMapItemPresent = styled.div`
  width: 0.75rem;
  height: 0.5rem;
  border: ${props => props.active ? `1px solid ${colors.middle}` : `1px solid ${colors.first}`};
  background-color: ${colors.first};
  margin-right: 1px;
`;

const HistoryMapItemMissing = styled.div`
  width: 0.75rem;
  height: 0.5rem;
  border: ${props => props.active ? `1px solid ${colors.accent}` : `1px solid ${colors.light}`};
  background-color: ${colors.light};
  margin-right: 1px;
`;

const SearchContainer = styled.div`
  padding: 0rem 2rem 1rem 0rem;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 2.5rem;
  background-color: ${colors.bright};
  background-image: url(${search});
  background-position: .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding: 0rem 1rem 0rem 2.3rem;
  margin-top: 1rem;
  box-shadow: 0rem 0.3rem 3rem .1rem ${colors.light};
  font-size: .75rem;
  border: 1px solid ${colors.bright};
  outline: none;
  color: ${colors.dark};
  -webkit-appearance: textfield;

  &::placeholder {
    color: ${colors.light};
  }
`;

const Hits = styled.ol`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-image: radial-gradient(#f0f0f0, ${colors.bright});
`;

const Hit = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.5;
  list-style-type: none;
  background-color: ${colors.bright};
  color: ${colors.first};
  border-bottom: 1px solid #eee;
  padding: 0.75rem 1.5rem;
  margin: 0 1.5rem;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
  }

  &:last-child {
    border-bottom-left-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }
`;

const Container = styled.div`
  width: 100%;
`;

const SecondaryAction = styled.div`
  display: flex;
`;

const ItemText = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  font-size: .75rem;
  font-weight: 500;
  color: ${colors.dark};
  line-height: 1.4;
`;

const ItemDescription = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.75rem;
  font-weight: 300;
  color: ${colors.middle};
  line-height: 1.3;
`;

const ItemStatus = styled.span`
  font-size: 0.75rem;
  font-weight: 300;
  background-color: ${colors.first};
  color: ${colors.bright};
  padding: 0 0.125rem;
  margin: 0 0.25rem;
  border-radius: 2px;
`;

const ItemIcon = styled.img`
  height: 1rem; 
`;

export {
  SearchBox,
  SearchHits,
  SearchHit
}
