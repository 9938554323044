import React from 'react';
import styled from "styled-components";
import colors from '../styles/colors';
import { Layout, Header, Heading, SubHeading, SubHeadingText, FirstWord, Content, MainAction } from '../Components/AppChrome';
import { MainButton } from '../Components/Button';

const newUser = (props) => {  
  return (
    <Layout>
      <Header>
        <Heading backLink={`/user`}>{props.practitioner.name}</Heading>
        <SubHeading>
          <SubHeadingText>
            <FirstWord>Ny</FirstWord> kund
          </SubHeadingText>
        </SubHeading>
      </Header>

      <Content>
        <Form>
          <InputText value={props.displayName} onChange={props.onChangeDisplayName} placeholder={'Gisela Holmqvist'}/>
          <InputEmail value={props.email} onChange={props.onChangeEmail} placeholder={'gisela.holmqvist@gmail.com'}/>
        </Form>
      </Content>

      <MainAction>
        <MainButton onClick={props.onCreateNewUser}>Skapa</MainButton>
      </MainAction>
    </Layout>
  );
}

export default newUser;

const Form = styled.div`
  padding: 1rem;
  padding-top: 7rem;
`;

const Input = styled.input`
  width: 100%;
  height: 2rem;
  background-color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 2px;
  border: 2px solid ${props => props.onChange ? colors.first : colors.light };
  outline: none;
  color: ${props => props.onChange ? colors.dark : colors.light };
  margin-bottom: 10px;

  &::placeholder {
    color: ${colors.light};
  }
`;

const Flex = styled.div`
  display: flex;
`;

const InputText = (props) => {
  return (
    <Flex>
      <Input 
        type={"text"}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
    </Flex>
  );
};

const InputEmail = (props) => {
  return (
    <Flex>
      <Input 
        type={"email"}
        value={props.value}
        onChange={props.onChange}
        tabIndex={0}
        placeholder={props.placeholder}
        autoFocus
      />
    </Flex>
  );
};
