import React from 'react'
import styled from 'styled-components';
import colors from '../../styles/colors';

const InputNumber = styled.input`
  width: 2rem;
  height: 1rem;
  font-size: 1rem;
  border-radius: 2px;
  border: 2px solid transparent;
  color: ${colors.bright};
  background-color: ${colors.first};
  text-align: center;
  margin: 0 3px;

  &:hover {
    background-color: ${colors.bright};
    color: ${colors.dark};
  }
`;

const Number = (props) => {
  return (
      <InputNumber type="tel" size="2" minLength="1" maxLength="2" min={props.min} max={props.max} value={props.value} onChange={props.onChange} onBlur={props.onBlur} onClick={props.onChange} onFocus={event => event.target.setSelectionRange(0, 2)} />
  );
};

export default Number;
