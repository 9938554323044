import styled from 'styled-components';
import posed from 'react-pose';
import colors from '../../styles/colors';

const MainButton = styled(posed.button({
  hoverable: true,
  pressable: true,
  init: {
    boxShadow: `0rem 0.4rem 1.5rem .1rem rgba(0,160,233,.5)`,
    // background: `linear-gradient(to bottom, ${colors.first} 0%, ${colors.firstLight} 100%)`,
  },
  hover: {
    boxShadow: `0rem 0.7rem 2rem 0.4rem rgba(0,160,233,.5)`,
    // background: `linear-gradient(to bottom, ${colors.firstLight} 0%, ${colors.first} 100%)`,
  },
  press: {
    boxShadow: `0rem 0rem 0rem 0rem rgba(0,160,233,.5)`
  }
}))`
  // background: linear-gradient(to bottom, ${colors.first} 0%, ${colors.firstLight} 100%);
  background-color: ${colors.first};
  border: 1px solid ${colors.first};
  border-radius: 2rem;
  color: ${colors.bright};
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 300;
  outline: none;
  overflow: visible;
  padding: .75rem 1.75rem;
  text-decoration: none;
  // text-shadow: 0 1px 0 rgba(0,0,0,.2);
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  white-space: nowrap;
`;

const WarningButton = styled(posed.button({
  hoverable: true,
  pressable: true,
  init: {
    boxShadow: `0.2rem 0.2rem 0.1rem rgba(0,0,0,.2), 0 0 0rem ${colors.red}`,
    background: `linear-gradient(to bottom, ${colors.red} 0%, ${colors.redLight} 100%)`,
  },
  hover: {
    boxShadow: `0.2rem 0.3rem 0.1rem rgba(0,0,0,.2), 0 0 0.3rem ${colors.red}`,
    background: `linear-gradient(to bottom, ${colors.redLight} 0%, ${colors.red} 100%)`,
  },
  press: {
    boxShadow: `0rem 0rem 0.1rem rgba(0,0,0,.2), 0 0 0rem ${colors.red}`
  }
}))`
  //background: linear-gradient(to bottom, #8f0000 0%, ${colors.red} 100%);
  background-color: ${colors.red};
  border: 1px solid #bbb;
  border-color: ${colors.red};
  border-radius: 2rem;
  color: ${colors.bright};
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 300;
  outline: none;
  overflow: visible;
  padding: 0.75rem 1.75rem;
  text-decoration: none;
  // text-shadow: 0 1px 0 rgba(0,0,0,.2);
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  white-space: nowrap;
`;

const SecondaryButton = styled(posed.button({
  hoverable: true,
  pressable: true,
  init: {
    boxShadow: `0.2rem 0.2rem 0.1rem rgba(0,0,0,.2), 0 0 0rem ${colors.first}`,
    // background: `linear-gradient(to bottom, ${colors.first} 0%, ${colors.firstLight} 100%)`,
  },
  hover: {
    boxShadow: `0.2rem 0.3rem 0.1rem rgba(0,0,0,.2), 0 0 0.3rem ${colors.first}`,
    // background: `linear-gradient(to bottom, ${colors.firstLight} 0%, ${colors.first} 100%)`,
 },
  press: {
    boxShadow: `0rem 0rem 0.1rem rgba(0,0,0,.2), 0 0 0rem ${colors.first}`
  }
}))`
  background: transparent;
  border: 1px solid #bbb;
  border-color: ${colors.first};
  border-radius: 2rem;
  color: ${colors.dark};
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 300;
  outline: none;
  overflow: visible;
  padding: 0.75rem 1.75rem;
  text-decoration: none;
  // text-shadow: 0 1px 0 rgba(0,0,0,.2);
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  white-space: nowrap;
`;

export {
  MainButton,
  WarningButton,
  SecondaryButton
}
