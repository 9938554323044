import React from 'react';
import { Layout, Header, Heading, SubHeading, SubHeadingText, FirstWord, Content, List, ListItem, MainAction } from '../Components/AppChrome';
import { MainButton } from '../Components/Button';

const showAgreement = (props) => {
  if (!props.practitioner || !props.agreement) {
    return null;
  }
  const agreementTitleParts = props.agreement.title && props.agreement.title.split(' ');
  const agreementTitleFirstWord = agreementTitleParts && agreementTitleParts.shift();
  const agreementTitleRest = agreementTitleParts && agreementTitleParts.join(' ');

  return (
    <Layout>
      <Header>
        <Heading>{props.practitioner.name}</Heading>
        <SubHeading>
            <SubHeadingText><FirstWord>{agreementTitleFirstWord}</FirstWord> {agreementTitleRest}</SubHeadingText>
        </SubHeading>
      </Header>
      <Content>
        <List>
          { props.agreement.text.map((line, index) => {
              return <ListItem key={index}>{line}</ListItem>
            }) }
        </List>
      </Content>
      <MainAction>
        <MainButton onClick={props.onAcceptAgreement}>Acceptera</MainButton>
      </MainAction>
    </Layout>
  );
}

export default showAgreement;
