import React, { Component } from 'react';
import { navigate, graphql } from "gatsby";
import { Router } from "@reach/router";
import { db, auth, firebase } from '../firebase';
import Home from '../components/home';
import UpdateVersion from '../components/updateVersion';
import ShowAgreement from '../components/showAgreement';
import NewUser from '../components/newUser';
import EditUser from '../components/editUser';
import Programs from '../components/programs';

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.searchBox = null;

    this.state = {
      authenticating: false,
      authUser: null,
      allUsers: [],
      displayName: '',
      email: '',
      searchText: '',
      users: [],
      user: null,
      allTemplates:[],
      allInstructions: [],
      version: null,
      agreement: null,
      templatesSnapshotUnsubscribe: null,
      versionSnapshotUnsubscribe: null
    };

    this.setSearchBoxRef = this.setSearchBoxRef.bind(this);
  }

  onSignOut = () => {
    console.log("Signing out");
    this.state.templatesSnapshotUnsubscribe();
    auth.doSignOut();
    this.setState({
      authenticating: false,
      authUser: null,
      allUsers: [],
      users: [],
      userId: '',
      user: null,
      searchText: ''
    }, () => {
      navigate('/');
    });
  }

  setSearchBoxRef(searchBoxRef) {
    this.searchBox = searchBoxRef;
    const self = this;
    window.requestAnimationFrame(() => {
      self.onFocusSearchBox();
    });
  }

  onFocusSearchBox() {
    if (this.searchBox) {
      this.searchBox.focus();
    }
  }

  onChangeDisplayName = event => {
    this.setState({ displayName: event.target.value });
  }

  onChangeEmail = event => {
    this.setState({ email: event.target.value });
  }

  onClearSearchText = () => {
    this.setState({searchText: ''});
  }

  onChangeSearchText = event => {
    const searchText = event.target.value;
    var searchTerm = new RegExp(searchText, 'gi');
    const users = this.state.allUsers.filter(user => user.name.match(searchTerm));

    this.setState({
      searchText: ''
    }, () => {
      this.setState({
        searchText,
        displayName: searchText,
        users
      });
    })

    window.scrollTo(0, 0);
  }

  onChangeSearchTextInstructions = event => {
    const searchText = event.target.value;
    var searchTerm = new RegExp(searchText, 'gi');
    let instructions = [];
    
    if (searchText.length === 1) {
      instructions = this.state.allInstructions.slice(0).sort((a, b) => Number(a.ordinal) - Number(b.ordinal));
    } else {
      instructions = this.state.allInstructions.filter(instruction => instruction.id.toUpperCase() === searchText.toUpperCase());
    }
    
    if (instructions.length === 0) {
      instructions = this.state.allInstructions.filter(instruction => instruction.name.match(searchTerm));
    }

    if (instructions.length === 0) {
      instructions = this.state.allInstructions.filter(instruction => instruction.id.match(searchTerm));
    }

    this.setState({
      searchText,
      instructions
    });

    window.scrollTo(0, 0);
  }

  onKeypress = event => {
    switch (event.keyCode) {
      case 13:  //enter
        if (this.state.users.length > 0) {
          event.currentTarget.id = this.state.users[0].id;
          this.onUserSelected(event);
        } else {
          navigate(`/user/new`);
        }
        break;
      case 27:  //escape
        this.setState({
          searchText: ''
        });
        break;
      default:
    }
  }

  onCancelImpersonation = event => {
    const user = this.state.practitioner;
    if (!user) {
      return;
    }
    this.setState({
      user,
      searchText: '',
      programs: [],
      allPrograms: []
    }, () => {
      navigate(`/user/${user.id}`);
      this.onFocusSearchBox();
    });
  }

  onUserSelected = event => {
    this.state.users.filter(user => user.id === event.currentTarget.id).forEach(user => {
      this.setState({
          user,
          searchText: '',
          displayName: '',
          email: ''
      }, () => {
        navigate(`user/${user.id}`);
      });
    });
  }

  onCreateNewUser = () => {
    const userId = this.state.email.toLowerCase();
    db.getOrCreateUser(this.state.practitioner, userId, this.state.displayName)
      .then(user => {
        this.setState({
          displayName: '',
          email: '',
          searchText: '',
          user: user,
          users: [],
          allUsers: [...this.state.allUsers, user]
        });

        navigate(`/user/${user.id}`);
      })
      .catch(errorMessage => { console.log(errorMessage); })
  }

  onUpdateUser = () => {
    const user = this.state.user || this.state.practitioner;
    const displayName = this.state.displayName || (this.state.user && this.state.user.name) || (this.state.practitioner && this.state.practitioner.name);
    const email = this.state.email || (this.state.user && this.state.user.email) || (this.state.user && this.state.user.id) || (this.state.practitioner && this.state.practitioner.email) || (this.state.practitioner && this.state.practitioner.id);
    db.updateUser(this.state.practitioner, user.id, displayName, email)
      .then(updatedUser => {
        const allUsers = this.state.allUsers.slice(0).map(user => {
          if (user.id === updatedUser.id) {
            user.name = updatedUser.name;
            user.email = updatedUser.email;
          }
          return user;
        });

        this.setState({
          email: '',
          displayName: '',
          searchText: '',
          user: updatedUser,
          users: [],
          allUsers
        });

        navigate(`/user/${updatedUser.id}`);
      })
      .catch(errorMessage => { console.log(errorMessage); })    
  }

  onDeleteUser = () => {
    db.deleteUser(this.state.practitioner, this.state.user.id)
      .then(deletedUser => {
        const allUsers = this.state.allUsers.filter(user => { 
          return user.id !== deletedUser.id
        });

        this.setState({
          email: '',
          displayName: '',
          searchText: '',
          user: null,
          users: [],
          allUsers
        }, () => {
          navigate(`/user/`);
        });
      })
      .catch(errorMessage => { console.log(errorMessage); })    
  }

  onShowProgram = () => {
    navigate(`/user/${this.getUserId()}/program`);
  }

  getUserId = (onlyUser = false) => { return (this.state.user && this.state.user.id) || (!onlyUser && this.state.practitioner && this.state.practitioner.id) };

  onAcceptAgreement = () => {
    db.acceptAgreement(this.state.practitioner, this.state.agreement.date).then(() => {
      navigate(`/`);
    })
  }

  componentDidMount() {
    console.log("Mounting user");
    const self = this;
    self.setState({
      authenticating: true
    });

    firebase.auth.onAuthStateChanged(user => {
      if (!user) {
        self.setState({ authenticating: false });
        console.log("Not authenticated.");
        navigate('/');
        return;
      }

      self.setState({
        authUser: user,
        authenticating: false
      });

      const versionSnapshotUnsubscribe = db.getVersionSnapshot().onSnapshot(languageRef => {
        if (this.state.version !== null) {
          console.log("Version:", this.state.version);
          navigate('/user/update');
        }
        this.setState({
          agreement: languageRef.data().agreement,
          version: languageRef.data().version
        });
      });
      this.setState({versionSnapshotUnsubscribe});

      db.getPractitioner()
        .then(practitioner => {
          self.setState({ practitioner });
          if (!practitioner.agreementDate || practitioner.agreementDate < this.state.agreement.date.toDate()) {
            navigate('/user/agreement');
          }
          return practitioner;
        })
        .then(practitioner => { return db.getUsers(practitioner); })
        .then(allUsers => {
          self.setState({ allUsers }, () => {
            if (self.state.searchText.length > 0) {
              self.onChangeSearchText({target: { value: self.state.searchText } });
            }
            console.log("Users loaded: ", allUsers.length);
          })
        })
        .then(() => { return db.getInstructions(this.state.practitioner.level); })
        .then((allInstructions) => {
          self.setState({ allInstructions }, () => {
          console.log("Instructions loaded: ", allInstructions.length); })
        })
        .then(() => {
          const templatesSnapshotUnsubscribe = db.getProgramsSnapshot(this.state.practitioner.id).onSnapshot(querySnapshot => {
            var allTemplates = [];
            querySnapshot.forEach(programRef => {
              const template = db.mapProgram(this.state.practitioner.id, programRef, true);
              if (!template.deleted) {
                allTemplates.push(template);
              }
            });
            this.setState({allTemplates}, () => {
              console.log("Templates loaded: ", allTemplates.length);
            });
          });
          this.setState({templatesSnapshotUnsubscribe});
        })
        .catch(errorMessage => { console.log(errorMessage); });
    });
  }

  componentWillUnmount() {
    if (this.state.templatesSnapshotUnsubscribe) {
      console.log("Unsubscribing templates snapshot");
      this.state.templatesSnapshotUnsubscribe();
    }

    if (this.state.versionSnapshotUnsubscribe) {
      console.log("Unsubscribing version snapshot");
      this.state.versionSnapshotUnsubscribe();
    }
  }

  render() {
    return (
      <Router>
        <Home path="/user/" practitioner={this.state.practitioner} allUsers={this.state.allUsers} users={this.state.users} user={this.state.user} setSearchBoxRef={this.setSearchBoxRef} searchText={this.state.searchText} onClearSearchText={this.onClearSearchText} onChangeSearchText={this.onChangeSearchText} onKeypress={this.onKeypress} onUserSelected={this.onUserSelected} onShowProgram={this.onShowProgram} onSignOut={this.onSignOut} />
        <Home path="/user/:userId" practitioner={this.state.practitioner} allUsers={this.state.allUsers} users={this.state.users} user={this.state.user} setSearchBoxRef={this.setSearchBoxRef} searchText={this.state.searchText} onClearSearchText={this.onClearSearchText} onChangeSearchText={this.onChangeSearchText} onKeypress={this.onKeypress} onUserSelected={this.onUserSelected} onCancelImpersonation={this.onCancelImpersonation} onShowProgram={this.onShowProgram} onSignOut={this.onSignOut} />
        <UpdateVersion path="/user/update" version={this.state.version} images={this.props.data.allFile.edges} />
        <ShowAgreement path="/user/agreement" practitioner={this.state.practitioner} agreement={this.state.agreement} onAcceptAgreement={this.onAcceptAgreement} />
        <NewUser path="/user/new" practitioner={this.state.practitioner} displayName={this.state.displayName} email={this.state.email} onChangeDisplayName={this.onChangeDisplayName} onChangeEmail={this.onChangeEmail} onCreateNewUser={this.onCreateNewUser} />
        <EditUser path="/user/:userId/edit" practitioner={this.state.practitioner} allUsers={this.state.allUsers} displayName={this.state.displayName} email={this.state.email} onChangeDisplayName={this.onChangeDisplayName} onChangeEmail={this.onChangeEmail} onUpdateUser={this.onUpdateUser} onDeleteUser={this.onDeleteUser} />
        <Programs path="/user/:userId/program/*" practitioner={this.state.practitioner} allUsers={this.state.allUsers} user={this.state.user} images={this.props.data.allFile.edges} allInstructions={this.state.allInstructions} allTemplates={this.state.allTemplates} />
      </Router>
    );
  }
}

export default UserPage;

export const query = graphql`
  query {
    allFile {
      edges {
        node {
          base,
          childImageSharp {
            fluid(maxWidth: 834) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
