import React from 'react';
import styled from "styled-components";
import colors from '../styles/colors';
import { Layout, Header, Heading, Content, MainAction } from '../Components/AppChrome';
import { MainButton, SecondaryButton } from '../Components/Button';

const sendProgram = (props) => {
  const user = props.user || (props.userId && props.allUsers.find(user => user.id === props.userId)) || props.practitioner;
  if (!user) { return null; }

  return (
    <Layout>
      <Header>   
        <Heading backLink={`/user/${user.id}/program/${props.programId || props.templateId}`}>{user.name}</Heading>
        <InputText value={props.programName} onChange={props.onChangeProgramName} placeholder={"Namn på programmet"} autoFocus />
      </Header>

      <Content>
        <Info>
          <InputTextArea value={props.message} onChange={props.onChangeMessage} placeholder={"Skriv ett meddelande till kunden."} autoFocus />
        </Info>
      </Content>

      { user.id === props.practitioner.id ?
        <MainAction>
          <MainButton onClick={props.onPublishProgramPractitioner}>Skicka till mig</MainButton>
        </MainAction>
      :
        <MainAction spaceBetween={true}>
          <SecondaryButton onClick={props.onPublishProgramPractitioner}>Till mig</SecondaryButton>
          <MainButton onClick={props.onPublishProgram}>Till kund</MainButton>
        </MainAction>
      
      }
    </Layout>
  );}

export default sendProgram;

const Info = styled.div`
  padding: 1rem;
  padding-top: 7rem;
`;

const Input = styled.input`
  width: 100%;
  line-height: 1.9rem;
  background-color: ${colors.bright};
  font-size: 1rem;
  border-radius: 2px;
  border: 0px solid transparent;
  outline: none;
  color: ${colors.dark};
  margin-right: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &::placeholder {
    color: ${colors.light};
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 10rem;
  background-color: white;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 2px;
  border: 2px solid ${colors.first};
  outline: none;
  color: ${colors.dark};
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;

  &::placeholder {
    color: ${colors.light};
  }
`;

const InputText = (props) => {
  return (
    <Flex>
      <Input 
        type={"text"}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
    </Flex>
  );
};

const InputTextArea = (props) => {
  return (
    <Flex>
      <TextArea 
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
    </Flex>
  );
};

const Flex = styled.div`
  display: flex;
`;
