import React from 'react';
import Img from "gatsby-image";
import styled from "styled-components";
import colors from '../../styles/colors';
import NumberInput from '../../Components/Number';
import remove from '../../images/remove.svg';
import down from '../../images/down.svg';
import up from '../../images/up.svg';

class SortableList extends React.Component {
  render() {
    const readOnly = this.props.onSelect ? false : true;
    return (
      <List>
        {this.props.items.map((item, index) =>
          this.props.currentExerciseNumber === index ?
            <ListItem
              id={index}
              data-id={index}            
              key={index}
              selected={true}
              onClick={this.props.onSelect}
              onKeyDown={this.props.onArrowKeys}
              tabIndex={index + 1}
            >
              <ItemSelectedSecondaryAction>
                <IconButton icon={up} onClick={this.props.onMoveUp} />
                <IconButton icon={down} onClick={this.props.onMoveDown} />
              </ItemSelectedSecondaryAction>
              <ItemTitleArea>
                <ItemText>
                  {item.name}
                </ItemText>
                {item.grouped && !item.groupedFirst ?
                  <ItemSubText selected={true}>
                    <NumberInput min={1} max={20} value={item.repeat} onChange={this.props.onChangeRepeat} />
                    {item.repeatUnit}
                  </ItemSubText>
                :
                  <ItemSubText selected={true}>
                    <NumberInput min={1} max={10} value={item.sets} onChange={this.props.onChangeSets} onBlur={this.props.onBlurInput} /> x
                    <NumberInput min={1} max={20} value={item.repeat} onChange={this.props.onChangeRepeat} onBlur={this.props.onBlurInput} />
                    {item.repeatUnit}
                  </ItemSubText>
                }
              </ItemTitleArea>
              <ItemSelectedAction>
                <DeleteButton onClick={this.props.onRemoveExercise} />
              </ItemSelectedAction>
            </ListItem>
          :
            <ListItem
              id={index}
              data-id={index}            
              key={index}
              selected={this.props.currentExerciseNumber === index}
              onClick={this.props.onSelect}
              onKeyDown={this.props.onArrowKeys}
              tabIndex={index + 1}
            >
              <GroupButtonSwitch readOnly={readOnly} selected={item.grouped} first={item.groupedFirst} sets={item.sets} onClick={this.props.onChangeGrouped}>{item.index ? item.index + 1 : index + 1}</GroupButtonSwitch>
              <ItemTitleArea>
                <ItemText readOnly={readOnly}>
                  {item.name}
                </ItemText>
                {item.grouped ?
                  <ItemSubText>
                    {item.repeat} {item.repeatUnit}
                  </ItemSubText>
                :
                  <ItemSubText>
                    {item.sets} x {item.repeat} {item.repeatUnit}
                  </ItemSubText>
                }
              </ItemTitleArea>
              <ItemAction readOnly={readOnly}>
                <TrackSwitch readOnly={readOnly} selected={item.multiTrack} onClick={this.props.onChangeMultiTrack}>S</TrackSwitch>
                <TrackSwitch readOnly={readOnly} selected={item.shortTrack} onClick={this.props.onChangeShortTrack}>M</TrackSwitch>
                <TrackSwitch readOnly={readOnly} selected={true} onClick={(event) => {event.stopPropagation()}}>L</TrackSwitch>
              </ItemAction>
              <ItemImage readOnly={readOnly}>
                { item.image ? <Image fluid={item.image} /> : null }
                { item.image2 ? <Image fluid={item.image2} /> : null }
              </ItemImage>
            </ListItem>
        )}
      </List>
    );
  }
};

const Image = styled(Img)`
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
`;

const List = styled.ol`
  margin: 0;
  padding: 0;
  font-size: .75rem;
  padding-top: 6rem;
  padding-bottom: 5rem;
`;

const ListItem = styled.li`
  display: flex;
  outline: none;
  align-items: center;
  width: 100%;
  height: 4rem;
  line-height: 1.5;
  list-style-type: none;
  text-transform: capitalize;
  background-color: ${props => props.selected ? colors.accent : colors.bright};
  color: ${props => props.selected ? colors.bright : colors.first};
  border-bottom: 1px solid #eee;
  cursor: pointer;
`;

const ItemAction = styled.div`
  display: ${props => props.readOnly ? 'none' : 'flex'};
  justify-content: start;
  margin-right: 0.5rem;
`;

const ItemImage = styled.div`
  display: ${props => props.readOnly ? 'flex' : 'none'};
  justify-content: start;
  margin-right: 0.5rem;
`;

const ItemSelectedAction = styled.div`
  display: flex;
  width: 5rem;
  justify-content: space-evenly;
`;

const ItemSelectedSecondaryAction = styled.div`
  display: flex;
  width: 5rem;
  flex-direction: column;
`;

const IconButton = (props) => {
  return (
    <ItemIcon src={props.icon} alt="" onClick={props.onClick} />
  );
}

const DeleteButton = (props) => {
  return (
    <ItemIcon src={remove} alt="Ta bort övning" onClick={props.onClick} />
  );
}

const ItemIcon = styled.img`
  height: 1.5rem; 
`;

const ItemTitleArea = styled.div`
  min-width: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;

const ItemText = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: .75rem;
  font-weight: 700;
  color: ${props => props.readOnly ? colors.dark : colors.dark};
  line-height: 1.4;
`;

const ItemSubText = styled.div`
  width: 100%;
  font-size: 0.75rem;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => props.selected ? `${colors.dark}` : `${colors.light}`};
`;

const GroupRepeat = styled.div`
  width: 100%;
  height: 0.4rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${colors.dark};
`;

const Index = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: ${colors.first};
  padding-bottom: .45rem;
`;

const GroupButtonSwitch = (props) => {
  return (
    <ItemGroupSwitch selected={props.selected} first={props.first} onClick={props.onClick}>
      <GroupRepeat selected={props.selected} first={props.first}>{props.first ? `${props.sets}x`: ''}</GroupRepeat>
      <Index readOnly={props.readOnly}>{props.children}</Index>
    </ItemGroupSwitch>
  );
}

const TrackSwitch = (props) => {
  return (
      <ItemTrackSwitch readOnly={props.readOnly} selected={props.selected} onClick={props.onClick}>{props.children}</ItemTrackSwitch>
  );
}

const ItemTrackSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: initial;
  text-align: center;
  height: 1.75rem;
  width: 1.75rem;
  font-size: .75rem;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 2px;
  border: 1px solid ${props => props.selected ? colors.first : colors.light};
  background-clip: padding-box;
  color: ${props => props.selected ? colors.bright : colors.dark};
  background-color: ${props => props.selected ? (props.readOnly ? colors.first : colors.first) : `transparent`};
  outline: none;
  cursor: pointer;
  margin-right: 0.1rem;
`;

const ItemGroupSwitch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 3.5rem;
  padding-left: 0.1rem;
  color: ${colors.first};
  box-sizing: border-box;
  border-left: ${props => props.selected ? `0.5rem solid ${colors.dark}` : '0.5rem solid transparent'};
`;

export default SortableList;
