import React from 'react';
import { Link } from "gatsby";
import styled from "styled-components";
import colors from '../styles/colors';
import { Layout, Header, Heading, SubHeading, SubHeadingText, FirstWord, Content, MainAction } from '../Components/AppChrome';
import SortableList from '../Components/SortableList';
import { MainButton, SecondaryButton } from '../Components/Button';

const TrackSwitch = (props) => {
  return (
      <ItemTrackSwitch readOnly={props.readOnly} selected={props.selected} onClick={props.onClick}>{props.children}</ItemTrackSwitch>
  );
}

const ItemAction = styled.div`
  display: flex;
  width: 10rem;
  justify-content: space-evenly;
`;

const ItemTrackSwitch = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // box-sizing: initial;
  // text-align: center;
  // height: 2rem;
  // width: 2rem;
  // font-size: 1rem;
  // font-weight: bold;
  // text-transform: uppercase;
  // border-radius: 2px;
  // border: ${props => props.readOnly ? '2px solid transparent' : '2px solid white'};
  // box-sizing: border-box;
  // background-clip: padding-box;
  // color: ${props => props.selected ? (props.readOnly ? colors.bright : colors.dark) : colors.bright};
  // background-color: ${props => props.selected ? (props.readOnly ? colors.first : colors.accent) : colors.first};
  // padding: 1px;
  // outline: none;
  // cursor: ${props => props.readOnly ? 'default' : 'pointer'};
  // margin-left: 0.125rem;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: initial;
  text-align: center;
  height: 1.75rem;
  width: 1.75rem;
  font-size: .75rem;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 2px;
  border: 1px solid ${props => props.selected ? colors.first : colors.light};
  background-clip: padding-box;
  color: ${props => props.readOnly ? colors.light : props.selected ? colors.bright : colors.dark};
  background-color: ${props => props.selected ? (props.readOnly ? colors.first : colors.first) : `transparent`};
  outline: none;
  cursor: pointer;
  margin-right: 0.1rem;

  `;

const program = (props) => {
  const user = props.user || (props.userId && props.allUsers.find(user => user.id === props.userId)) || props.practitioner;
  if (!user) { return null; }
  const multiTrackFilterEnabled = props.exercises.find(exercise => exercise.multiTrack) != null;
  const shortTrackFilterEnabled = props.exercises.find(exercise => exercise.shortTrack) != null;
  const programNameParts = props.currentProgram && props.currentProgram.name.split(' ');
  const programNameFirstWord = programNameParts && programNameParts.shift();
  const programNameRest = programNameParts && programNameParts.join(' ');
  return (
    <Layout>
      <Header>
        <Heading backClick={props.onCancelShowDetails}>{user.name}</Heading>
        <SubHeading>
            {props.currentProgram ?
              <SubHeadingText><FirstWord>{programNameFirstWord}</FirstWord> {programNameRest}</SubHeadingText>
            :
              <SubHeadingText></SubHeadingText>
            }
          <ItemAction>
            {multiTrackFilterEnabled ? 
              <TrackSwitch selected={props.filter === 'S'} onClick={props.filterMultiTrack}>S</TrackSwitch>
            :
              <TrackSwitch readOnly={true}>S</TrackSwitch>
            }
            {shortTrackFilterEnabled ? 
              <TrackSwitch selected={props.filter === 'M'} onClick={props.filterShortTrack}>M</TrackSwitch>
            :
              <TrackSwitch readOnly={true}>M</TrackSwitch>
            }
            <TrackSwitch selected={props.filter === 'L'} onClick={props.filterStandardTrack}>L</TrackSwitch>
          </ItemAction>
        </SubHeading>
      </Header>

      <Content>
        <SortableList items={props.exercises.filter(exercise => 
          (props.filter === 'S' && exercise.multiTrack)
          || (props.filter === 'M' && exercise.shortTrack)
          || (props.filter === 'L')
        )} />
      </Content>

      { props.currentProgram ?
        <MainAction spaceBetween={true}>
            <Link to={`/user/${props.userId}/program/${props.programId}/send`}>
              <SecondaryButton>{props.currentProgram.sent ? 'Skicka igen' : 'Skicka'}</SecondaryButton>
            </Link>
            { props.templateId && user.id !== props.practitioner.id ? 
              <MainButton onClick={this.onNewProgram}>Redigera</MainButton>
            :
              <Link to={`/user/${props.userId}/program/${props.programId}/edit`}>
                <MainButton>Redigera</MainButton>
              </Link>
            }
        </MainAction>
        :
          null
        }
    </Layout>
  );
}

export default program;
