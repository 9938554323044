import React from 'react';
import styled from "styled-components";
import colors from '../styles/colors';
import { Layout, Header, Heading, SubHeading, SubHeadingText, FirstWord, Content, MainAction } from '../Components/AppChrome';
import { WarningButton, MainButton } from '../Components/Button';

const editUser = (props) => {
  const user = props.user || (props.userId && props.allUsers.find(user => user.id === props.userId)) || props.practitioner;
  if (!user) { return null; }
  const displayName = props.displayName || user.name;
  const email = props.email || user.email || user.id;
  
  return (
    <Layout>
      <Header>
        <Heading backLink={`/user/${props.userId}`}>{user.name}</Heading>
        <SubHeading>
          <SubHeadingText>
            <FirstWord>Redigera</FirstWord> konto
          </SubHeadingText>
        </SubHeading>
      </Header>

      <Content>
        <Form>
          <InputText value={displayName} onChange={props.onChangeDisplayName} placeholder={'Gisela Holmqvist'}/>
          <InputEmail value={email} onChange={props.onChangeEmail} placeholder={'gisela.holmqvist@gmail.com'}/>
        </Form>
      </Content>

      {props.userId !== props.practitioner.id ?
        <MainAction spaceBetween={true}>
          <WarningButton onClick={props.onDeleteUser}>Radera</WarningButton>
          <MainButton onClick={props.onUpdateUser}>Spara</MainButton>
        </MainAction>
      :
        <MainAction>
          <MainButton onClick={props.onUpdateUser}>Spara</MainButton>
        </MainAction>
      }

    </Layout>
  );
}

export default editUser;

const Form = styled.div`
  padding: 1rem;
  padding-top: 7rem;
`;

const Input = styled.input`
  width: 100%;
  height: 2rem;
  background-color: white;
  padding: .5rem 1rem .5rem 1rem;
  font-size: .75rem;
  border-radius: 2px;
  border: 1px solid ${colors.bright};
  outline: none;
  color: ${props => props.onChange ? colors.dark : colors.light };
  // margin-bottom: 10px;

  &::placeholder {
    color: ${colors.light};
  }
`;

const Flex = styled.div`
  display: flex;
  border-bottom: 1px solid #eee;
`;

const InputText = (props) => {
  return (
    <Flex>
      <Input 
        type={"text"}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
    </Flex>
  );
};

const InputEmail = (props) => {
  return (
    <Flex>
      <Input 
        type={"email"}
        value={props.value}
        onChange={props.onChange}
        tabIndex={0}
        placeholder={props.placeholder}
        autoFocus
      />
    </Flex>
  );
};
