import React, { Component } from 'react';
import { navigate } from "gatsby";
import { Router } from "@reach/router";
import { db } from '../firebase';
import ShowProgram from '../components/showProgram';
import SendProgram from '../components/sendProgram';
import EditProgram from '../components/editProgram';
import ShowInstruction from '../components/showInstruction';

class Program extends Component {
  constructor(props) {
    super(props);
    this.searchBox = null;

    this.state = {
      currentProgram: null,
      exercisesSnapshotUnsubscribe: null,
      filter: 'L',
      message: 'Lycka till med träningen!\n\n',
      programName: '',
      searchText: '',
      currentExerciseNumber: null,
      nextExerciseId: 1,
      exercises: [],
      instructions: [],
      instructionStep: 0,
      instructionDetails: false
    };

    this.setSearchBoxRef = this.setSearchBoxRef.bind(this);
    this.filterMultiTrack = this.filterMultiTrack.bind(this);
    this.filterShortTrack = this.filterShortTrack.bind(this);
    this.filterStandardTrack = this.filterStandardTrack.bind(this);
  }

  setSearchBoxRef(searchBoxRef) {
    this.searchBox = searchBoxRef;
    const self = this;

    //TODO: RAF takes 60-160ms, make asynchronous?
    window.requestAnimationFrame(() => {
      self.onFocusSearchBox();
    });
  }

  onFocusSearchBox() {
    if (this.searchBox) {
      this.searchBox.focus();
    }
  }

  onClearSearchText = () => {
    this.setState({searchText: ''});
  }

  onChangeSearchText = event => {
    const searchText = event.target.value;
    var searchTerm = new RegExp(searchText, 'gi');
    let instructions = [];
    
    //TODO: setTimeout to execute asynchronously, takes 200ms currently
    if (searchText.length === 1) {
      instructions = this.props.allInstructions.slice(0).sort((a, b) => Number(a.ordinal) - Number(b.ordinal));
    } else {
      instructions = this.props.allInstructions.filter(instruction => instruction.id.toUpperCase() === searchText.toUpperCase());
    }
    
    if (instructions.length === 0) {
      instructions = this.props.allInstructions.filter(instruction => instruction.name.match(searchTerm));
    }

    if (instructions.length === 0) {
      instructions = this.props.allInstructions.filter(instruction => instruction.id.match(searchTerm));
    }

    this.setState({
      searchText,
      instructions
    });

    window.scrollTo(0, 0);
  }

  onCancelProgram = () => {
    if (this.state.exercisesSnapshotUnsubscribe) {
      this.state.exercisesSnapshotUnsubscribe();
    }
    this.setState({exercises: []});
    navigate(`/user/${this.props.userId}/program`);
  }

  getUserId = (onlyUser = false) => { return (this.props.userId) || (this.props.user && this.props.user.id) || (!onlyUser && this.props.practitioner && this.props.practitioner.id) };

  filterMultiTrack() {
    this.setState({filter: 'S'});
  }

  filterShortTrack() {
    this.setState({filter: 'M'});
  }

  filterStandardTrack() {
    this.setState({filter: 'L'});
  }

  onPublishProgram = () => {
    const userId = this.props.userId || this.props.user || this.props.practitioner;
    db.publishProgram(userId, this.state.currentProgram.originalId, this.state.programName, this.state.message, false);
    navigate(`/user/${this.props.userId}/program`);
  }

  onPublishProgramPractitioner = () => {
    const userId = this.props.userId || this.props.user || this.props.practitioner;
    db.publishProgram(userId, this.state.currentProgram.originalId, this.state.programName, this.state.message, true);
    navigate(`/user/${this.props.userId}/program`);
  }

  onChangeProgramName = event => {
    this.setState({programName: event.target.value});    
  }

  onChangeMessage = event => {
    this.setState({message: event.target.value});    
  }

  onSelect = event => {
    const selectedIndex = Number(event.currentTarget.id);
    if (this.state.currentExerciseNumber === selectedIndex) {
      this.setState({ currentExerciseNumber: null});
    } else {
      this.setState({ currentExerciseNumber: Number(event.currentTarget.id)});
    }
  }

  onKeypress = event => {
    switch (event.keyCode) {
      case 13:  //enter
        event.currentTarget.id = this.state.instructions.length > 0 ? this.state.instructions[0].id : null;
        this.onAddExercise(event);
        break;
      case 27:  //escape
        this.onClearSearchText();
        break;
      case 32:  //space
        this.setState({ currentExerciseNumber: null });
        break;
      case 37:  //left
      case 38:  //up
        if (this.state.exercises && this.state.exercises.length > 0) {
          event.currentTarget.parentElement.parentElement.id = this.state.currentExerciseNumber;
          this.onMoveUp(event);
        }
        break;
      case 39:  //right
      case 40:  //down
        if (this.state.exercises && this.state.exercises.length > 0) {
          event.currentTarget.parentElement.parentElement.id = this.state.currentExerciseNumber;
          this.onMoveDown(event);
        }
        break;
      default:
    }
  }

  onChangeSets = event => {
    event.stopPropagation();
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.parentElement.id);    
    const newExercises = this.state.exercises.slice(0);
    const currentExercise = newExercises[currentExerciseIndex];
    const value = Number(event.target.value);
    if (currentExercise.sets !== value) {
      currentExercise.sets = isNaN(value) ? 1 : value;
      this.setState({exercises: newExercises});
    }
  }
  
  onChangeRepeat = event => {
    event.stopPropagation();
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.parentElement.id);
    const newExercises = this.state.exercises.slice(0);
    const currentExercise = newExercises[currentExerciseIndex];
    const value = Number(event.target.value);
    if (currentExercise.repeat !== value) {
      currentExercise.repeat = isNaN(value) ? 1 : value;
      this.setState({exercises: newExercises});
    }
  }
  
  onBlurInput = event => {
    event.stopPropagation();
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.parentElement.id);    
    const currentExercise = this.state.exercises[currentExerciseIndex];
    db.updateExercise(this.getUserId(), this.state.currentProgram.originalId, currentExercise);
  }

  markFirstInGroup = exercises => {
    let previousGrouped = false;
    let previousGroupedFirst = false;
    const newExercises = exercises.forEach(exercise => {
      const groupedFirstBefore = exercise.groupedFirst;
      exercise.groupedFirst = !previousGroupedFirst && ((exercise.grouped && exercise.groupedFirst) || (exercise.grouped && !previousGrouped));
      if (groupedFirstBefore !== exercise.groupedFirst) {
        db.updateExercise(this.getUserId(), this.state.currentProgram.originalId, exercise);
      }
      previousGrouped = exercise.grouped;
      previousGroupedFirst = exercise.groupedFirst;
    });
    return newExercises;
  }

  onChangeGrouped = event => {
    event.stopPropagation();
    const newExercises = this.state.exercises.slice(0);
    const currentExerciseNumber = Number(event.currentTarget.parentElement.id);
    const currentExercise = newExercises[currentExerciseNumber];
    const previousExercise = newExercises[currentExerciseNumber - 1];
  
    if (currentExercise.groupedFirst) {
      currentExercise.grouped = false;
      currentExercise.groupedFirst = false;
    } else if (currentExercise.grouped) {
      if (previousExercise && previousExercise.groupedFirst) {
        currentExercise.grouped = false;
      } else {
        currentExercise.groupedFirst = true;
      }
    } else {
      currentExercise.grouped = true;
    }

    this.markFirstInGroup(newExercises);
    this.setState({exercises: newExercises}, () => {
      db.updateExercise(this.getUserId(), this.state.currentProgram.originalId, currentExercise);
    });
  }

  onChangeShortTrack = event => {
    event.stopPropagation();
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.id);
    const newExercises = this.state.exercises.slice(0);
    const currentExercise = newExercises[currentExerciseIndex];
    currentExercise.shortTrack = !currentExercise.shortTrack;
    this.setState({exercises: newExercises}, () => {
      db.updateExercise(this.getUserId(), this.state.currentProgram.originalId, currentExercise);
    });
  }

  onChangeMultiTrack = event => {
    event.stopPropagation();
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.id);
    const newExercises = this.state.exercises.slice(0);
    const currentExercise = newExercises[currentExerciseIndex];
    currentExercise.multiTrack = !currentExercise.multiTrack;
    this.setState({exercises: newExercises}, () => {
      db.updateExercise(this.getUserId(), this.state.currentProgram.originalId, currentExercise);
    });
  }

  onMoveUp = event => {
    event.stopPropagation();
    const newExercises = this.state.exercises.slice(0);
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.id);
    const currentExerciseId = newExercises[currentExerciseIndex].id;
    const previousExerciseIndex = currentExerciseIndex < 1 ? 0 : currentExerciseIndex - 1;
    const previousExerciseId = newExercises[previousExerciseIndex].id;
    const previousExercise = newExercises[previousExerciseIndex];
    newExercises[previousExerciseIndex] = newExercises[currentExerciseIndex];
    newExercises[currentExerciseIndex] = previousExercise;
    this.markFirstInGroup(newExercises);
    this.setState({
      exercises: newExercises,
      currentExerciseNumber: previousExerciseIndex
    }, () => {
      db.moveExercise(this.getUserId(), this.state.currentProgram.originalId, previousExerciseId, currentExerciseIndex);
      db.moveExercise(this.getUserId(), this.state.currentProgram.originalId, currentExerciseId, previousExerciseIndex);
    });
  }

  onMoveDown = event => {
    event.stopPropagation();
    const newExercises = this.state.exercises.slice(0);
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.id);
    const currentExerciseId = newExercises[currentExerciseIndex].id;
    const nextExerciseIndex = currentExerciseIndex === newExercises.length - 1 ? currentExerciseIndex : currentExerciseIndex + 1;
    const nextExerciseId = newExercises[nextExerciseIndex].id;
    const nextExercise = newExercises[nextExerciseIndex];
    newExercises[nextExerciseIndex] = newExercises[currentExerciseIndex];
    newExercises[currentExerciseIndex] = nextExercise;
    this.markFirstInGroup(newExercises);
    this.setState({
      exercises: newExercises,
      currentExerciseNumber: nextExerciseIndex
    }, () => {
      db.moveExercise(this.getUserId(), this.state.currentProgram.originalId, nextExerciseId, currentExerciseIndex);
      db.moveExercise(this.getUserId(), this.state.currentProgram.originalId, currentExerciseId, nextExerciseIndex);
    });
  }

  onRemoveExercise = event => {
    const addingExercise = this.state.exercises.length === 1 ? true : false;    
    const currentExerciseNumber = addingExercise ? 0 : this.state.currentExerciseNumber > 1 ? this.state.currentExerciseNumber - 1 : 1;
    const exercise = this.state.exercises[this.state.currentExerciseNumber];
    const exercises = this.state.exercises.filter((_, index) => index !== Number(event.currentTarget.parentElement.parentElement.id));
    this.markFirstInGroup(exercises);
    this.setState({
      exercises,
      currentExerciseNumber,
      addingExercise
    }, () => {
      db.removeExercise(this.getUserId(), this.state.currentProgram.originalId, exercise);

      for (let i = currentExerciseNumber + 1; i < this.state.exercises.length; i++) {
        db.moveExercise(this.getUserId(), this.state.currentProgram.originalId, exercises[i].id, i);
      }
    });
    this.onFocusSearchBox();
  }

  onAddExercise = event => {
    const currentExerciseNumber = this.state.currentExerciseNumber === null ? this.state.exercises.length : this.state.currentExerciseNumber + 1;
    this.state.instructions.filter(instruction => instruction.id === event.currentTarget.id).forEach(instruction => {
      const newExercises = this.state.exercises.slice(0);
      newExercises.splice(currentExerciseNumber, 0, {
        id: this.state.nextExerciseId,
        instructionId: instruction.id,
        name: instruction.name,
        shortText: instruction.shortText,
        text: instruction.text || '',
        videoId: instruction.videoId,
        repeat: instruction.repeat || 4,
        repeatUnit: instruction.repeatUnit,
        sets: instruction.sets || 2,
        grouped: false,
        groupedFirst: false,
        shortTrack: false,
        multiTrack: false,
        steps: instruction.steps,
        about: instruction.about,
        affects: instruction.affects
      });
      this.markFirstInGroup(newExercises);
      this.setState({
        exercises: newExercises,
        currentExerciseNumber: currentExerciseNumber,
        nextExerciseId: this.state.nextExerciseId + 1,
        searchText: ''
      }, () => {
        db.addExercise(this.getUserId(), this.state.currentProgram.originalId, newExercises[currentExerciseNumber], currentExerciseNumber);

        for (let i = currentExerciseNumber + 1; i < this.state.exercises.length; i++) {
          db.moveExercise(this.getUserId(), this.state.currentProgram.originalId, newExercises[i].id, i);
        }
      });
      this.props.navigate(`edit`);
      this.onFocusSearchBox();
    })
  }

  onShowInstruction = (event, instructionId) => {
    //TODO: Make asynchronous. stopPropagation + setState takes 211ms right now.
    event.stopPropagation();
    this.setState({instructionStep: 0});
    this.props.navigate(`instruction/${instructionId}`);
  }

  onToggleInstructionDetails = () => {
    this.setState({
      instructionDetails: !this.state.instructionDetails
    })
  }

  onPreviousStep = () => {
    const instructionStep = this.state.instructionStep < 1 ? 0 : this.state.instructionStep - 1;
    this.setState({instructionStep});
  }

  onNextStep = (stepCount) => {
    const instructionStep = this.state.instructionStep >= stepCount - 1 ? stepCount - 1 : this.state.instructionStep + 1;
    this.setState({instructionStep});
  }

  componentDidMount() {
    console.log("Mounting program");
    if (!this.state.exercisesSnapshotUnsubscribe) {
      console.log("Subscribing exercises snapshot");
      const template = this.props.allTemplates.find(program => program.id === this.props.programId);
      const program = this.props.allPrograms.find(program => program.id === this.props.programId) || template;
      if (program) {
        this.subscribeExerciseSnapshot(program);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.exercisesSnapshotUnsubscribe) {
      console.log("Subscribing exercises snapshot");
      const template = this.props.allTemplates.find(program => program.id === this.props.programId);
      const program = this.props.allPrograms.find(program => program.id === this.props.programId) || template;
      if (program) {
        this.subscribeExerciseSnapshot(program);
      }
    }

    if (prevProps.allInstructions.length === 0 && this.props.allInstructions.length > 0) {
      console.log("Refreshing instructions");
      this.onChangeSearchText({target: { value: this.state.searchText } });
    }
  }

  componentWillUnmount() {
    if (this.state.exercisesSnapshotUnsubscribe) {
      console.log("Unsubscribing exercises snapshot");
      this.state.exercisesSnapshotUnsubscribe();
    }
  }

  subscribeExerciseSnapshot(program) {
    const images = this.props.images;
    const exercisesSnapshotUnsubscribe = db.getExercisesSnapshot(program.userId, program.originalId).onSnapshot(querySnapshot => {
      var exercises = [];
      querySnapshot.forEach(exerciseRef => {
        const exercise = db.mapExercise(exerciseRef);
        const image = images.find(image => image.node.base === exercise.steps[0].image);
        const image2 = images.find(image => exercise.steps.length > 1 && image.node.base === exercise.steps[1].image);
        exercise.index = exercises.length;
        exercise.image = images && exercise.steps[0].image && image ? image.node.childImageSharp.fluid : null;
        exercise.image2 = images && exercise.steps.length > 1 && exercise.steps[1].image && image2 ? image2.node.childImageSharp.fluid : null;
        exercises.push(exercise);
      });
      this.setState({
        exercises,
        nextExerciseId: Math.max(...exercises.map(exercise => exercise.id), 0) + 1
      }, () => {
        this.props.updateExerciseMapForProgram(program, exercises);
      });
    });
    this.setState({
      filter: 'L',
      currentProgram: program,
      programName: program.name,
      message: program.message || this.state.message,
      exercisesSnapshotUnsubscribe
    });
  }

  render() {
    return (
      <Router>
        <ShowProgram path="/" practitioner={this.props.practitioner} allUsers={this.props.allUsers} user={this.props.user} exercises={this.state.exercises} onCancelShowDetails={this.onCancelProgram} currentProgram={this.state.currentProgram} filter={this.state.filter} filterMultiTrack={this.filterMultiTrack} filterShortTrack={this.filterShortTrack} filterStandardTrack={this.filterStandardTrack} />
        <SendProgram path="/send" practitioner={this.props.practitioner} allUsers={this.props.allUsers} user={this.props.user} programName={this.state.programName} message={this.state.message} onChangeProgramName={this.onChangeProgramName} onChangeMessage={this.onChangeMessage} onPublishProgramPractitioner={this.onPublishProgramPractitioner} onPublishProgram={this.onPublishProgram} />
        <EditProgram path="/edit" practitioner={this.props.practitioner} allUsers={this.props.allUsers} user={this.props.user} exercises={this.state.exercises} currentExerciseNumber={this.state.currentExerciseNumber} currentProgram={this.state.currentProgram} onSelect={this.onSelect} onArrowKeys={this.onArrowKeys} onChangeShortTrack={this.onChangeShortTrack} onChangeMultiTrack={this.onChangeMultiTrack} onChangeSets={this.onChangeSets} onChangeRepeat={this.onChangeRepeat} onBlurInput={this.onBlurInput} onChangeGrouped={this.onChangeGrouped} onMoveUp={this.onMoveUp} onMoveDown={this.onMoveDown} onRemoveExercise={this.onRemoveExercise} onChangeSearchText={this.onChangeSearchText} searchText={this.state.searchText} onClearSearchText={this.onClearSearchText} instructions={this.state.instructions} onAddExercise={this.onAddExercise} onShowInstruction={this.onShowInstruction} setSearchBoxRef={this.setSearchBoxRef} onKeypress={this.onKeypress} exerciseMap={this.props.exerciseMap} exerciseMapIndex={this.props.exerciseMapIndex} />
        <ShowInstruction path="/instruction/:instructionId" practitioner={this.state.practitioner} allUsers={this.state.allUsers} user={this.state.user} allInstructions={this.props.allInstructions} images={this.props.images} instructionStep={this.state.instructionStep} instructionDetails={this.state.instructionDetails} onToggleInstructionDetails={this.onToggleInstructionDetails} onPreviousStep={this.onPreviousStep} onNextStep={this.onNextStep} onAddExercise={this.onAddExercise} />
      </Router>
    );
  }
}

export default Program;
