import React from 'react';
import { Link } from "gatsby";
import { Layout, Header, Heading, Content, MainAction } from '../Components/AppChrome';
import { SearchBox, SearchHits, SearchHit } from '../Components/Search';
import SortableList from '../Components/SortableList';
import { MainButton } from '../Components/Button';

const editProgram = (props) => {
  const user = props.user || (props.userId && props.allUsers.find(user => user.id === props.userId)) || props.practitioner;
  if (!user) { return null; }
  if (!props.exercises) { return null; }

  return (
    <Layout>
      <Header>
        <Heading backLink={`/user/${user.id}/program/${props.programId}`}>{user.name}</Heading>
        <SearchBox setSearchBoxRef={props.setSearchBoxRef} value={props.searchText} onChange={props.onChangeSearchText} onArrowKeys={props.onKeypress} placeholder={"RS, Quad, Hooklying..."} />
      </Header>
      
      <Content>
      { !props.searchText || props.searchText.length === 0 ?
        <SortableList
          items={props.exercises}
          currentExerciseNumber={props.currentExerciseNumber}
          onSelect={props.onSelect}
          onArrowKeys={props.onKeypress}
          onChangeShortTrack={props.onChangeShortTrack}
          onChangeMultiTrack={props.onChangeMultiTrack}
          onChangeSets={props.onChangeSets}
          onChangeRepeat={props.onChangeRepeat}
          onBlurInput={props.onBlurInput}
          onChangeGrouped={props.onChangeGrouped}
          onMoveUp={props.onMoveUp}
          onMoveDown={props.onMoveDown}
          onRemoveExercise={props.onRemoveExercise}
        />
      :
        <SearchHits hits={props.instructions} historyMap={props.exerciseMap} historyMapIndex={props.exerciseMapIndex}>
          <SearchHit onClick={props.onAddExercise} onDetailsClick={props.onShowInstruction} />
        </SearchHits>
      }
      </Content>

      { props.currentProgram ?
        <MainAction>
          <Link to={`/user/${user.id}/program/${props.programId}`}><MainButton>Granska</MainButton></Link>
          {/* <Link to={`/user/${props.userId}/program/${props.programId}/send`}><SecondaryButton>{props.currentProgram.sent ? 'Skicka igen' : 'Skicka'}</SecondaryButton></Link> */}
        </MainAction>
      :
        null
      }
    
    </Layout>
  );
}

export default editProgram;
