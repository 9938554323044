import React, { Component } from 'react';
import { Layout, Header, Heading, Content, MainAction } from '../Components/AppChrome';
import { SearchBox, SearchHits, SearchHit } from '../Components/Search';
import { MainButton } from '../Components/Button';

class ListPrograms extends Component {
  render() {
    const user = this.props.user || (this.props.userId && this.props.allUsers.find(user => user.id === this.props.userId)) || this.props.practitioner;
    if (!user) { return null; }
  
    return (
      <Layout>
        <Header>
          <Heading backLink={`/user/${this.props.userId}`}>{user.name}</Heading>
          <SearchBox setSearchBoxRef={this.props.setSearchBoxRef} value={this.props.searchText} onChange={this.props.onChangeSearchText} onArrowKeys={this.props.onKeypress} placeholder={"Sök program"} />
        </Header>
  
        <Content>
            <SearchHits hits={this.props.programs}>
              <SearchHit onClick={this.props.onShowDetails} onDelete={this.props.onDeleteProgram} />
            </SearchHits>
        </Content>
  
        <MainAction>
          <MainButton onClick={this.props.onNewProgram}>Skapa nytt</MainButton>
        </MainAction>
      </Layout>
    );
  }
}

export default ListPrograms;
