import React from 'react';
import { navigate } from 'gatsby';
import { Layout, Header, Heading, Content, MainAction } from '../Components/AppChrome';
import { SearchBox, SearchHits, SearchHit } from '../Components/Search';
import { MainButton } from '../Components/Button';

const onNewClick = () => {
  navigate(`/user/new`);
}

const home = (props) => {
  const user = props.user || (props.userId && props.allUsers.find(user => user.id === props.userId)) || props.practitioner;
  if (!user) { return null; }

  if (!props.searchText || props.searchText.length === 0) {
  return (
    <Layout>
      <Header>
        <Heading
          backClick={props.practitioner && props.userId !== props.practitioner.id ? props.onCancelImpersonation : null}
          editLink={`/user/${user.id}/edit`}
        >
          { user.name }
        </Heading>
        <SearchBox setSearchBoxRef={props.setSearchBoxRef} value={props.searchText} onChange={props.onChangeSearchText} placeholder={`Sök bland ${props.allUsers.length} kunder`} />
      </Header>
      
      <Content>
      </Content>

      {/* { props.practitioner && props.practitioner.level >= 1500 ?
        <MainAction spaceBetween={true}>
          <MainButton>Behandling</MainButton>
          <MainButton onClick={props.onShowProgram}>Program</MainButton>
        </MainAction>
      : */}
        <MainAction>
          <MainButton onClick={props.onShowProgram}>Program</MainButton>
        </MainAction>
      {/* } */}
    </Layout>
  );
  } else {
    return (
      <Layout>
        <Header>
          <Heading backClick={props.onClearSearchText}>{user.name}</Heading>
          <SearchBox setSearchBoxRef={props.setSearchBoxRef} value={props.searchText} onChange={props.onChangeSearchText} onArrowKeys={props.onKeypress} placeholder={"Sök efter kund"} />
        </Header>
  
        <Content>
          <SearchHits hits={props.users} onNewClick={onNewClick} newText={'+ Ny kund'} >
            <SearchHit onClick={props.onUserSelected} />
          </SearchHits>
        </Content>
  
        <MainAction>
          <MainButton onClick={props.onSignOut}>Logga ut</MainButton>
        </MainAction>
      </Layout>
  
    );  
  }
}

export default home;
