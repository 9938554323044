import React, { Component } from 'react';
import { Router } from "@reach/router";
import { db } from '../firebase';
import ListPrograms from '../components/listPrograms';
import Program from '../components/program';

class Programs extends Component {
  constructor(props) {
    super(props);
    this.searchBox = null;
    this.exerciseMapLength = 5;

    this.state = {
      allPrograms: [],
      allTemplates: [],
      programs: [],
      searchText: '',
      maxProgramId: 1,
      exerciseMap: {},
      exerciseMapIndex: null,
      programsSnapshotUnsubscribe: null,
    };

    this.setSearchBoxRef = this.setSearchBoxRef.bind(this);
    this.updateExerciseMapForProgram = this.updateExerciseMapForProgram.bind(this);
  }

  setSearchBoxRef(searchBoxRef) {
    this.searchBox = searchBoxRef;
    const self = this;
    window.requestAnimationFrame(() => {
      self.onFocusSearchBox();
    });
  }

  onFocusSearchBox() {
    if (this.searchBox) {
      this.searchBox.focus();
    }
  }

  onClearSearchText = () => {
    this.setState({searchText: ''});
  }

  onChangeSearchText = event => {
    const searchText = event.target.value;
    var searchTerm = new RegExp(searchText, 'gi');
    const templates = this.props.allTemplates.filter(template => template.name.match(searchTerm) || template.id.toUpperCase() === searchText.toUpperCase());
    const programs = this.state.allPrograms.filter(program => program.name.match(searchTerm) || program.id.toUpperCase() === searchText.toUpperCase());

    this.setState({
      searchText,
      programs: this.props.practitioner && (this.props.userId === this.props.practitioner.id) ? programs : programs.concat(templates)
    });

    window.scrollTo(0, 0);
  }

  onKeypress = event => {
    switch (event.keyCode) {
      case 13:  //enter
        event.currentTarget.id = this.state.programs.length > 0 ? this.state.programs[0].id : null;
        this.onProgramSelected(event);
        break;
      case 27:  //escape
        this.onClearSearchText();
        break;
      default:
    }
  }

  onNewProgram = (templateId) => {
    const template = this.props.allTemplates.find(template => template.id === templateId);
    db.createProgram(this.props.userId, null, this.state.maxProgramId, (template && template.name) || '', (template && template.message) || '').then(program => {
      if (template) {
        db.getExercises(this.props.practitioner.id, template.originalId).then(exercises => {
          exercises.forEach((exercise, index) => { db.addExercise(this.props.userId, program.id, exercise, index + 1)});
        });
      }
      return program;
    }).then((program) => {
      this.props.navigate(`${this.props.userId + program.id}/edit`);
    });
  }

  onDeleteProgram = event => {
    event.stopPropagation();
    const program = this.state.allPrograms.find(program => program.id === event.currentTarget.parentElement.id);
    if (!program) { return; }
    db.deleteProgram(this.props.practitioner, program.userId, program.originalId);
  }

  onProgramSelected = event => {
    if (this.state.allPrograms.find(program => program.id === event.currentTarget.id)) {
      this.props.navigate(`${event.currentTarget.id}`);
    } else {
      this.onNewProgram(event.currentTarget.id);
    }
  }

  componentDidMount() {
    console.log("Mounting programs");
    if (!this.state.programsSnapshotUnsubscribe) {
      this.subscribeProgramsSnapshot();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allTemplates.length === 0 && this.props.allTemplates.length > 0) {
      console.log("Refreshing templates");
      this.onChangeSearchText({target: { value: this.state.searchText } });
    }
  }

  componentWillUnmount() {
    if (this.state.programsSnapshotUnsubscribe) {
      console.log("Unsubscribing programs snapshot");
      this.state.programsSnapshotUnsubscribe();
    }
  }

  subscribeProgramsSnapshot() {
    const programsSnapshotUnsubscribe = db.getProgramsSnapshot(this.props.userId).onSnapshot(querySnapshot => {
      var allPrograms = [];
      var maxProgramId = 0;
      querySnapshot.forEach(programRef => {
        const program = db.mapProgram(this.props.userId, programRef, false);
        maxProgramId = Number(program.originalId) > maxProgramId ? Number(program.originalId) : maxProgramId;
        if (!program.deleted) {
          allPrograms.push(program);
        }
      });
      this.setState({
        allPrograms,
        maxProgramId
      }, () => {
        this.onChangeSearchText({target: { value: this.state.searchText } });
        console.log("Programs loaded: ", allPrograms.length);
        this.generateExerciseMap();
      });
    });
    this.setState({programsSnapshotUnsubscribe});
  }

  generateExerciseMap() {
    const length = this.state.allPrograms.length > this.exerciseMapLength ? this.exerciseMapLength : this.state.allPrograms.length;
    const exercisesPromises = [];
    for (var i = 0; i < length; i++) {
      exercisesPromises[i] = db.getExercises(this.props.userId, this.state.allPrograms[i].originalId);
    }
    
    Promise.all(exercisesPromises).then(programs => {
      const exerciseMap = programs.reduce((exerciseMap, exercises, index) => {
        return this.updateExerciseMap(exerciseMap, exercises, index);
      }, this.state.exerciseMap);
      this.setState({exerciseMap});
    });
  }

  updateExerciseMapForProgram(currentProgram, exercises) {
    const index = this.state.allPrograms.findIndex(program => program.id === currentProgram.id);
    const exerciseMapIndex = this.exerciseMapLength - index - 1;
    if (index > -1 && index < this.exerciseMapLength) {
      const originalExerciseMap = Object.assign({}, this.state.exerciseMap);
      const clearedExerciseMap = this.clearExerciseMapForIndex(originalExerciseMap, exerciseMapIndex);
      const exerciseMap = this.updateExerciseMap(clearedExerciseMap, exercises, exerciseMapIndex);
      this.setState({
        exerciseMap,
        exerciseMapIndex
      });
    } else {
      this.setState({exerciseMapIndex: null});
    }
  }

  clearExerciseMapForIndex(exerciseMap, index) {
    for (var exercise in exerciseMap) {
      exerciseMap[exercise][index] = 0;
    }
    return exerciseMap;
  }

  updateExerciseMap(exerciseMap, exercises, index) {
    exercises.forEach(exercise => {
      if (!exerciseMap[exercise.instructionRef.id]) {
        exerciseMap[exercise.instructionRef.id] = Array.apply(null, Array(this.exerciseMapLength)).map(Number.prototype.valueOf, 0);
      }
      exerciseMap[exercise.instructionRef.id][index] = 1;
    });
    return exerciseMap;
  }

  render() {
    return (
      <Router>
        <ListPrograms path="/" practitioner={this.props.practitioner} allUsers={this.props.allUsers} user={this.props.user} programs={this.state.programs} setSearchBoxRef={this.setSearchBoxRef} searchText={this.state.searchText} onChangeSearchText={this.onChangeSearchText} onKeypress={this.onKeypress} onShowDetails={this.onProgramSelected} onNewProgram={this.onNewProgram} onDeleteProgram={this.onDeleteProgram} />
        <Program path="/:programId/*" practitioner={this.props.practitioner} allUsers={this.props.allUsers} user={this.props.user} images={this.props.images} allPrograms={this.state.allPrograms} allTemplates={this.state.allTemplates} allInstructions={this.props.allInstructions} exerciseMap={this.state.exerciseMap} exerciseMapIndex={this.state.exerciseMapIndex} updateExerciseMapForProgram={this.updateExerciseMapForProgram} />
      </Router>
    );
  }
}

export default Programs;
