import React from 'react';
import { navigate } from "gatsby"
import { Layout } from '../Components/AppChrome';
import { MainButton } from '../Components/Button';

const onRefreshClick = () => {
  navigate(`/`);
}

const updateVersion = (props) => {
  const image = props.images.find(image => image.node.base === "newfunction.jpg");
  return (
    <Layout image={image}>
      <MainButton onClick={onRefreshClick}>Uppdatera</MainButton>
    </Layout>
  );
}

export default updateVersion;
