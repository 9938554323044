import React from 'react';
import Img from "gatsby-image";
import styled from "styled-components";
import colors from '../styles/colors';
import { Layout, Header, Heading, Content, MainAction } from '../Components/AppChrome';
import { MainButton, SecondaryButton } from '../Components/Button';

const showInstruction = (props) => {
  const instruction = props.allInstructions && props.allInstructions.find(instruction => instruction.id === props.instructionId);
  if (!instruction) { return null; }
  const name = instruction.name;
  const displayName = name[0].toUpperCase() + name.substring(1);
  const step = props.instructionStep;
  const imageFilename = getImageFilename(instruction, step);
  const image = props.images.find(image => image.node.base === imageFilename);
  const title = instruction.steps[step].title ? instruction.steps[step].title.replace('{repeat}', instruction.repeat).replace('{sets}', instruction.sets): null;
  const revealText = props.instructionDetails ? "mindre" : "mer";
  //const text = instruction.steps.length > 2 ? '• ' + step.text.replace(/\. /g, '\n• ') : step.text;
  //{ String.fromCharCode(65 + step) }.&nbsp;

  return (
    <Layout>
      <Header>
        <Heading backClick={() => window.history.back()} multiLine={true}>{displayName}</Heading>
      </Header>
      
      <Content>
        <ImageSpacer>
        { image ? <Image fluid={image.node.childImageSharp.fluid} /> : null }
        </ImageSpacer>

        <InfoBottom onClick={props.onToggleInstructionDetails}>
          { title ?
              <Title>{title} <TextAction>{revealText}</TextAction></Title>
            :
              instruction.steps[step].text
          }

          { 
            <List>
            {
              (props.instructionDetails && instruction.steps[step].directives) ?
                instruction.steps[step].directives.map((directive, index) => <li key={index}>{directive}</li>)
              :
                null
            }
            </List>
          }
        </InfoBottom>
      </Content>

      <MainAction spaceBetween={true}>
        <SecondaryButton hidden={step === 0} onClick={props.onPreviousStep}>&lt;</SecondaryButton>
        <MainButton id={instruction.id} onClick={props.onAddExercise}>Lägg till</MainButton>
        <SecondaryButton hidden={step >= instruction.steps.length - 1} onClick={() => props.onNextStep(instruction.steps.length)}>&gt;</SecondaryButton>
      </MainAction>
    </Layout>
  );
}

const getImageFilename = (instruction, currentStep) => {
  let previousStep = currentStep;
  while (previousStep > 0 && !instruction.steps[previousStep].image) {
    previousStep--;
  }
  return instruction.steps[previousStep].image
}

const Title = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4;
  padding: 1rem 1rem 0rem 1rem;
`;

const TextAction = styled.span`
  font-size: 0.75rem;
  text-decoration: underline;
  color: ${colors.first};
`;

const List = styled.ul`
  line-height: 1.4;
  margin: 0;
  padding: 0.375rem 1rem 5rem 2rem;
`;

const Image = styled(Img)`
  width: 100%;
  height: 100vw;
  max-height: 843px;
`;

const ImageSpacer = styled.div`
  padding-top: 6rem;
`;

const InfoBottom = styled.div`
  width: 100%;
  max-width: 843px;
  position: absolute;
  bottom: 0;
  background-color: white;
  opacity: 0.9;
  cursor: pointer;
`;

export default showInstruction;
